import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _ from 'lodash'

// Import language files
// import en from './en'
//import en from './en'
//import de from './de'

import api from '@/services/api'
import store from '@/store'

const cache = {}
let lastLocaleKey = null

// localization
Vue.use(VueI18n)

// Export VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en'
})

export async function setLocale(locale) {
  await updateLocaleSource(store.state.source, store.state.survey && store.state.survey.name, locale)

  i18n.locale = locale || 'en'

  await window.vm.$forceUpdate()
}

export function getLocale() {
  return i18n.locale
}

export function t(text) {
  return i18n.t(text)
}

export async function updateLocaleSource(source, survey, locale) {
  locale = locale || getLocale()

  let localeKey = source + (survey ? '/' + survey : '') + '/' + locale

  if (lastLocaleKey == localeKey) {
    return
  }

  lastLocaleKey = localeKey

  let localeCache = cache[locale]

  if (!localeCache) {
    let baseTranslations = (await api.call('getTranslations', {
      vars: {
        locale: locale
      }
    })).data

    localeCache = cache[locale] = {
      translations : baseTranslations,
      sourcesCache: {}
    }
  }

  let translations = localeCache.translations
  let sourcesCache = localeCache.sourcesCache

  if (source) {
    let sourceCache = sourcesCache[source]

    if (!sourceCache) {
      let sourceTranslations = (await api.call('getSourceTranslations', {
        vars: {
          source: source,
          locale: locale
        }
      })).data

      sourceCache = localeCache[source] = {
        translations: sourceTranslations,
        surveysCache: {}
      }
    }

    translations = _.merge(translations, sourceCache.translations)
    let surveysCache = sourceCache.surveysCache

    if (survey) {
      let surveyCache = surveysCache[survey]
  
      if (!surveyCache) {
        let surveyTranslations = (await api.call('getSurveyTranslations', {
          vars: {
            source: source,
            survey: survey,
            locale: locale
          }
        })).data
  
        surveyCache = surveysCache[survey] = {
          translations: surveyTranslations
        }
      }
  
      _.merge(translations, surveyCache.translations)
    }
  }
  
  i18n.setLocaleMessage(locale, translations)
}

/*export async function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(`@/lang/${lang}.js`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}*/

export default i18n